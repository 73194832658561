import React from 'react';

import logo from '../Asset 10@3x.webp'

function ComingSoonPage() {
  


  return (
    <div className="w-full h-full bg-blue-500">
      <div class="w-full flex-wrap justify-center my-auto py-48">
        <div class="w-full flex justify-center">
          <img className={"w-48"} src={logo} alt="Arunchatra logo"/>
        </div>
        <div class="w-full flex justify-center my-12">
          <span className={"text-white text-2xl lg:text-4xl"}>COMING SOON</span>
        </div>

        <footer class="w-full">
          <div class="w-full flex justify-start my-48 ml-4">
              <span className={"text-yellow-500 text-xs lg:text-sm justify-center text-center"}>&copy; Arunchatra Holdings Co., Ltd.</span>
          </div>
        </footer>

      </div>
    </div>
  )
}

export default ComingSoonPage;