import React, { useState, useRef } from 'react';

import logo from '../Asset 10@3x.webp'

import icMenu from '../more.png'
import icSocial from '../ic_social.png'

import imgCover from '../shutterstock_2074721800.jpg'
import imgWareHouse from '../shutterstock_1119927347.jpg'
import imgHealthTech from '../shutterstock_736694506.jpg'
import imgSocial from '../shutterstock_715013050.jpg'


function HomePage() {
  
  const [ isShow , setIsShow ] = useState(false)
  
  const refMedMobile = useRef(null)
  const refWareHouseMobile = useRef(null)
  const refSocialMobile = useRef(null)

  const refAboutUs= useRef(null)
  const refContactUs= useRef(null)
  const refOurTech= useRef(null)
  

  function setShowMenu(){
    if(isShow === false){
      setIsShow(true)
    }
    else{
      setIsShow(false)
    }
  }

  return (
    <div className="flex w-full h-full bg-white">
      <div className={'  flex-wrap w-48 bg-white border  z-40 overflow-auto right-0 mt-14 '+ ( (isShow === true )? ' absolute lg:hidden flex ' : ' hidden ' ) }>
        <div className='flex w-full py-2 px-2 border-b border-black hover:bg-gray-200 bg-white'  onClick={ (e)=> { refAboutUs.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) } }>
          <label>ABOUT US</label>
        </div>
        <div className='flex w-full py-2 px-2 border-b border-black  bg-white'>
          <label>OUR TECH</label>
        </div>
        <div className='flex w-full py-2 px-2 hover:bg-gray-200 bg-white pl-12' onClick={ (e)=> { refMedMobile.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) } }>
          <label>Applied Medical Technology</label>
        </div>
        <div className='flex w-full py-2 px-2  hover:bg-gray-200 bg-white pl-12' onClick={ (e)=> { refWareHouseMobile.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) } }>
          <label>Warehouse Tech</label>
        </div>
        <div className='flex w-full py-2 px-2  hover:bg-gray-200 bg-white pl-12' onClick={ (e)=> { refSocialMobile.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) } }>
          <label>Social Listening</label>
        </div>
        <div className='flex w-full py-2 px-2 border-black hover:bg-gray-200 bg-white'  onClick={ (e)=> { refContactUs.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) } }>
          <label>CONTACT US</label>
        </div>
      </div>

      <div class=" absolute w-full flex-wrap overflow-auto">
        <div className={" flex  w-full p-2  bg-blue-500 h-18 lg:h-24"}>
          <div className="  w-11/12 lg:w-6/12 my-auto ">
            <img src={logo} alt="" className={" w-12  lg:w-24 my-auto lg:mx-28 "} />
          </div>

          <div className='flex flex-wrap  w-1/12 lg:w-6/12 justify-start lg:justify-end pr-0 lg:pr-36'>
            <img src={icMenu} alt="" onClick={()=>{ setShowMenu() }} className={"flex lg:hidden my-auto  w-6 mx-auto "} />
            <div className='hidden lg:flex my-14  mr-12'   onClick={ (e)=> { refAboutUs.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) } }>
              <label className='text-yellow-500'>ABOUT US</label>
            </div>
            <div className='hidden lg:flex my-auto mr-12'   onClick={ (e)=> { refOurTech.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) } }>
              <label className='text-yellow-500'>OUR TECH</label>
            </div>
            <div className='hidden lg:flex my-auto '   onClick={ (e)=> { refContactUs.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) } }>
              <label className='text-yellow-500'>CONTACT US</label>
            </div>
          </div>
        </div>

        <div className="flex w-full mt-3 px-3 lg:px-28">
          <img src={imgWareHouse} alt="Cover"  />
        </div>

        <div ref={refAboutUs} className="flex flex-wrap  px-3 lg:px-28 mt-6 lg:mt-20  mb-12">
          <div className="flex w-full">
            <label className='flex font-bold text-lg lg:text-3xl text-black mx-0 lg:mx-auto'>ABOUT US</label>
          </div>
          <div className="flex w-full mt-3">
            <p className='text-left text-md lg:text-lg'>Arunchatra believes in connecting the world with the ASEAN region. With our local market, operational and execution expertise, regional network and proven track record, Arunchatra only selects partners that will disrupt the status quo.</p>
          </div>

          <div className="flex w-full mt-3">
            <p className='text-left text-md lg:text-lg'>Arunchatra is home to a team of innovators and visionaries. We are a team of doctors, investors, policy makers, and strategists.
With our common goal of improving the society, we each come to the table with our strengths and unique propositions.</p>
          </div>
        </div>

        <div className="hidden flex-wrap  mt-8 px-6">
          <div className="flex flex-wrap w-1/3 px-3" >
            <div className="flex">
              <img src={icSocial} alt="" />
            </div>
            <div className="flex mx-auto">
              <label className='font-bold mt-3  text-center text-sm'>APPLIED MEDICAL TECHNOLOGY</label>
            </div>
          </div>

          <div className="flex flex-wrap w-1/3 px-3 " >
            <div className="flex">
              <img src={icSocial} alt="" />
            </div>
            <div className="flex mx-auto">
              <label className='font-bold justify-center mt-3 text-center text-sm'>WAREHOUSE TECH</label>
            </div>
          </div>

          <div className="flex flex-wrap w-1/3 px-3" >
            <label></label>
            <div className="flex">
              <img src={icSocial} alt="" />
            </div>
            <div className="flex mx-auto">
              <label className='font-bold justify-center mt-3 text-center text-sm'>SOCIAL LISTENING</label>
            </div>
          </div>
        </div>


        <div ref={refMedMobile} className="flex lg:hidden flex-wrap mt-6 px-3  py-3 bg-gray-200">
          <div className="flex flex-wrap w-full" >
            <div className="w-full" >
              <img src={imgHealthTech} className='' alt="APPLIED MEDICAL TECHNOLOGY" />
            </div>
            <div className="flex w-full">
              <label className='flex  w-full font-bold mt-3 justify-start text-left text-lg'>APPLIED MEDICAL TECHNOLOGY</label>
            </div>
            <div className="flex flex-wrap  w-full" >
              <p className='text-left'>By owning an ecosystem of state of the art medical technologies, combined with medical big data, Arunchatra is positioned to be a key player in the future of medical smart cities.</p>
            </div>
          </div>
        </div>


        <div ref={refWareHouseMobile} className="flex lg:hidden  flex-wrap  mt-3 px-3 py-3">
          <div className="flex flex-wrap w-full" >
            <div className="w-full" >
              <img src={imgCover} className='' alt="WAREHOUSE TECH" />
            </div>
            <div className="flex w-full">
              <label className='flex  w-full font-bold mt-3 justify-start text-left text-lg'>WAREHOUSE TECH</label>
            </div>
            <div className="flex flex-wrap  w-full" >
              <p className='text-left'>Through the use of patented technology, we are able to bring increased standardization, automation and Optimization to any new or existing systems.</p>
            </div>
          </div>
        </div>

        <div  ref={refSocialMobile} className="flex lg:hidden  flex-wrap mt-3 px-3 py-3 bg-gray-200">

          <div className="flex flex-wrap w-full" >
            <div className="w-full" >
              <img src={imgSocial} className='' alt="SOCIAL LISTENING" />
            </div>
            <div className="flex w-full">
              <label className='flex  w-full font-bold mt-3 justify-start text-left text-lg'>SOCIAL LISTENING</label>
            </div>
            <div className="flex flex-wrap  w-full" >
              <p className='text-left'>Embracing the power and availability of online social data, combined with proprietary technologies to process and analyze these huge data sets, Arunchatra is able to turn data into insights to aid our clients make more precise decision.</p>
            </div>
          </div>
        </div>

        <div ref={refOurTech} className="hidden lg:flex  flex-wrap mt-3  lg:mt-20 px-3 lg:px-28 py-3 bg-gray-200">
          <div className="flex w-full mb-6 mt-6 ">
            <label className='flex font-bold  text-lg lg:text-3xl  text-black mx-auto'>OUR TECH</label>
          </div>
          <div className=" flex-wrap w-1/3 px-6" >
            <div className="w-full" >
              <img src={imgHealthTech} className='h-64' alt="APPLIED MEDICAL TECHNOLOGY"  />
            </div>
            <div className=" w-full">
              <label className='flex  w-full font-bold mt-4 justify-start text-left text-xl'>APPLIED MEDICAL TECHNOLOGY</label>
            </div>
            <div className=" flex-wrap  w-full mt-2" >
              <p className='text-left text-md lg:text-lg'>By owning an ecosystem of state of the art medical technologies, combined with medical big data, Arunchatra is positioned to be a key player in the future of medical smart cities.</p>
            </div>
          </div>

          <div className=" flex-wrap w-1/3  px-6" >
            <div className="w-full" >
              <img src={imgCover} className='h-64'  alt="WAREHOUSE TECH"  />
            </div>
            <div className="mt-4 w-full">
              <label className='  w-full font-bold mt-4 justify-start text-left text-xl'>WAREHOUSE TECH</label>
            </div>
            <div className=" flex-wrap  w-full mt-2" >
              <p className='text-left text-md lg:text-lg'>Through the use of patented technology, we are able to bring increased standardization, automation and Optimization to any new or existing systems.</p>
            </div>
          </div>

          <div className=" flex-wrap w-1/3  px-6" >
            <div className="w-full" >
              <img src={imgSocial} className='h-64'  alt="SOCIAL LISTENING"  />
            </div>
            <div className=" w-full">
              <label className='flex  w-full font-bold mt-4 justify-start text-left text-xl'>SOCIAL LISTENING</label>
            </div>
            <div className=" flex-wrap  w-full mt-2" >
              <p className='text-left text-md lg:text-lg'>Embracing the power and availability of online social data, combined with proprietary technologies to process and analyze these huge data sets, Arunchatra is able to turn data into insights to aid our clients make more precise decision.</p>
            </div>
          </div>


        </div>


        <div ref={refContactUs} className="flex flex-wrap  px-3 lg:px-28  mt-12  lg:mt-20  mb-12">
          <div className="flex w-full">
            <label className='flex font-bold  text-lg lg:text-3xl text-black '>CONTACT US</label>
          </div>
          <div className="flex w-full mt-3">
            <p className=' text-md lg:text-lg'>
              <label className='text-lg lg:text-xl font-bold'>Arunchatra Holdings Co., Ltd.</label><br/>
              Glas Haus Building<br/>
              1 Sukhumvit Road Soi 25<br/>
              9th Floor Unit 901/1, Wattana<br/>
              Bangkok, Thailand 10110<br/><br/>

              +66(0)2-661 7038-9
            </p>
          </div>
        </div>


        <footer class="w-full border-t bg-blue-500 mt-6">
          <div class="w-full flex justify-start">
              <span className={"text-yellow-500 text-xs lg:text-sm justify-center text-center my-3 pl-3 lg:pl-28"}>&copy; Copyright 2022 Arunchatra Holdings Co., Ltd. All rights reserved</span>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default HomePage;