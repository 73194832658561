import { Suspense }  from 'react';
import  { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css';

import ComingSoonPage from '../src/pages/ComingSoonPage.js'
import HomePage from '../src/pages/HomePage.js'

function App() {
  return (
  <Suspense  fallback="loading">
    <BrowserRouter>
      <Routes>

        <Route exact path="/404" element={<ComingSoonPage />} />
        <Route exact path="/" element={<HomePage />} />
        
      </Routes>
    </BrowserRouter>
  </Suspense>

  );
}

export default App;
